<template>
  <div class="alert-history">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/Alert History.png" />
        <span>Alert History</span>
      </div>
      <el-button type="info" @click="$router.push({ path: '/home', query: { tabsIndex: 1 } })">
        Show More
      </el-button>
    </div>

    <div class="alert-history__query">
      <el-select
        v-for="item in selectView"
        :key="item.key"
        v-model="queryFrom[item.key]"
        size="mini"
        clearable
        :placeholder="item.placeholder"
        @change="getAlertList"
      >
        <el-option
          v-for="dict in item.options"
          :key="dict.dictLabel"
          :label="dict.dictLabel"
          :value="dict.dictValue"
        ></el-option>
      </el-select>
    </div>

    <el-table
      v-loading="loading"
      :data="alertList"
      style="width: 100%"
      :height="pxConvertRem(154)"
      @sort-change="handleSortCustom"
    >
      <el-table-column prop="Severity" sortable="custom" label="Severity" width="120">
        <div class="row-img__wrap _flex _flex-align__center" slot-scope="{ row }">
          <img :src="iconFun(row.severity)" />
          {{ row.severity }}
        </div>
      </el-table-column>
      <el-table-column prop="defectId" sortable="custom" label="Defect ID" width="120">
      </el-table-column>
      <el-table-column prop="defectType" sortable="custom" label="Category" width="120">
      </el-table-column>
      <el-table-column prop="createdTime" sortable="custom" label="Created" min-width="150">
        <template slot-scope="{ row }">
          <div>
            {{ timeRender(row.createdTimeStr) }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved, import/extensions
import Minor from '@/assets/icons/home/Minor.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import Moderate from '@/assets/icons/home/Moderate.png'
// eslint-disable-next-line import/no-unresolved, import/extensions
import Critical from '@/assets/icons/home/critical.png'
import { alertFindPage, alertTowerLocation } from '@/api/home'
import { parseTime, optionsFun } from '@/utils'
import common from '@/common'

export default {
  props: {
    time: String
  },
  data() {
    return {
      queryFrom: {
        severity: '',
        location: '',
        type: '',
        orderBy: '',
        sort: '',
        pageNo: 1,
        pageSize: 3,
        towerCode: this.$store.state.user.currentTowerCode
      },
      alertList: [],
      loading: true,
      selectView: [
        {
          key: 'severity',
          placeholder: 'Severity',
          options: common.constData.severity
        },
        {
          key: 'location',
          placeholder: 'All Location',
          options: []
        },
        {
          key: 'type',
          placeholder: 'All Category',
          options: optionsFun(['Rusting Issue', 'Tilt Angle'])
        }
      ]
    }
  },
  watch: {
    time: {
      handler(val) {
        // if (val.includes('00:00:00')) this.queryFrom.beginTime = val
        // else this.queryFrom.beginTime = `${val} 00:00:00`
        this.queryFrom.reportDateStr = val
        this.getAlertList()
        this.getTowerLocation()
      },
      immediate: true
    }
  },
  mounted() {
    
  },
  methods: {
    iconFun(status) {
      let imgSrc = ''
      switch (status) {
        case 'critical':
        case 'Critical':
          imgSrc = Critical
          break
        case 'major':
        case 'Major':
          imgSrc = Critical
          break
        case 'moderate':
        case 'Moderate':
          imgSrc = Moderate
          break
        case 'minor':
        case 'Minor':
          imgSrc = Minor
          break
        default:
      }

      return imgSrc
    },
    timeRender(time) {
      if (!time) return ''
      return `${parseTime(time, 'reg')} ${parseTime(time, '{h}:{i}')}`
    },
    getTowerLocation() {
      alertTowerLocation({
        towerCode: this.$store.state.user.currentTowerCode, 
        reportDatestr: this.queryFrom.reportDateStr
      }).then((res) => {
        this.selectView.find((item) => item.key === 'location').options = optionsFun(res.result)
      })
    },
    // 获取警告信息
    getAlertList() {
      this.loading = true
      alertFindPage({
        ...this.queryFrom,
        // reportDateStr: this.queryFrom.beginTime.split(' ')[0]
      })
        .then((res) => {
          this.loading = false
          this.alertList = res.result.rows
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleSortCustom({ prop, order }) {
      this.queryFrom.orderBy = prop
      this.queryFrom.sort = order ? order.replace('ending', '') : ''
      this.getAlertList()
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-history {
  .title-box {
    margin-bottom: 15px;
  }

  &__query {
    line-height: 28px;
    margin-bottom: 15px;
    > span {
      margin-right: 20px;
      font-size: 12px;
    }
    ::v-deep .el-select {
      width: 100px;
      & + .el-select {
        margin-left: 27px;
      }
      .el-input__inner {
        font-weight: 600;
        height: 21px;
        line-height: 21px;
        color: var(--color-black);
        border: none;
        border-bottom: 1px solid var(--color-info);
        border-radius: 0px;
        background: rgba(0, 0, 0, 0);
        padding: 0;
        &::placeholder {
          font-weight: 600;
          color: var(--color-black);
        }
      }

      .el-input__suffix {
        right: 0;
      }
      & .el-select__caret.el-icon-arrow-up {
        width: auto;
        height: auto;
        &::before {
          content: '\e78f';
          color: var(--color-black);
        }
      }
    }
  }

  ::v-deep .el-table {
    color: var(--color-black);
    font-size: 12px;
    font-weight: 600;

    &__cell {
      padding: 8px 0 !important;
    }

    thead {
      color: inherit;
      .el-table__cell {
        padding: 0 !important;
      }
    }

    .sort-caret.ascending {
      border-bottom-color: var(--color-info);
    }
    .ascending .sort-caret.ascending {
      border-bottom-color: var(--color-primary);
    }
    .sort-caret.descending {
      border-top-color: var(--color-info);
    }
    .descending .sort-caret.descending {
      border-top-color: var(--color-primary);
    }
  }

  ::v-deep .el-pagination {
    padding: 0;
    .btn-prev,
    .btn-next,
    .el-pager li {
      width: 26px;
      height: 26px;
      border-radius: 20px;
      padding: 0;
      text-align: center;
    }
  }

  .row-img__wrap {
    > img {
      width: 18px;
      height: 18px;
      margin-right: 5px;
    }
  }
}
</style>
